<script lang="ts" setup>
const cookieConsent = ref<boolean>(false);
const cookie = useCookie<boolean>('cookie-consent');

const checkCookieConsent = (): void => {
  cookieConsent.value = !!cookie.value;
};

const acceptCookies = (): void => {
  cookie.value = true;
  cookieConsent.value = true;
};

checkCookieConsent();
</script>

<template>
  <div  v-if="!cookieConsent"
         class="max-w-screen-lg mx-auto fixed bg-white inset-x-5 md:p-5 p-2 md:bottom-2 bottom-16 rounded-lg drop-shadow-2xl flex gap-4 flex-wrap md:flex-nowrap text-center md:text-left items-center justify-center md:justify-between z-50">
    <div class="w-full flex gap-2 items-center justify-center">
      <img src="/img/icons/cookie.svg" alt="cookie">
      <span class="md:text-lg text-sm text-brand-grey">
        Мы используем куки для улучшения вашего опыта на нашем сайте.
        <nuxt-link to="/docs/51" class="text-brand-primary md:text-lg text-sm whitespace-nowrap  hover:underline">Узнать больше</nuxt-link>
      </span>
      </div>
    <div class="flex gap-4 items-center flex-shrink-0">
      <button @click="acceptCookies" class="bg-brand-primary px-5 py-2 text-white rounded-md hover:bg-brand-primary-hover focus:outline-none">Принять все</button>
    </div>
  </div>
</template>

<style scoped>
</style>