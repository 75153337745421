<script setup>
const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth'
    })
}
</script>

<template>
    <div class="pb-[70px] md:pb-0 bg-brand-bright"></div>
    <div class="block md:hidden bg-brand-blue fixed bottom-0 w-full z-30">
        <div class="container flex items-center justify-between">
            <NuxtLink active-class="bg-[#149CFD]" to="/" class="flex flex-col items-center gap-2 py-1.5 px-3">
                <img src="/img/icons/mobile/home.svg" width="25" height="25" alt="Главная">
                <p class="font-medium text-white text-[10px]">Главная</p>
            </NuxtLink>
            <NuxtLink active-class="bg-[#149CFD]" to="/catalog" class="flex flex-col items-center gap-2 py-1.5  px-3">
                <img src="/img/icons/mobile/catalog.svg" width="25" height="25" alt="Каталог">
                <p class="font-medium text-white text-[10px]">Каталог</p>
            </NuxtLink>
            <span @click="scrollToTop" class="flex flex-col items-center gap-2 py-1.5  px-3">
                <img src="/img/icons/mobile/up.svg" width="18" height="18" alt="Вверх">
                <p class="font-medium text-white text-[10px]">Вверх</p>
            </span>
            <NuxtLink active-class="bg-[#149CFD]" to="/favorites" class="flex flex-col items-center gap-2 py-1.5 px-3">
                <img src="/img/icons/mobile/saved.svg" width="25" height="25" alt="Избранное">
                <p class="font-medium text-white text-[10px]">Избранное</p>
            </NuxtLink>
            <!-- <NuxtLink to="#" class="flex flex-col items-center gap-2  px-3">
                <img src="/img/icons/mobile/exit.svg" alt="">
                <p class="font-medium text-white text-[10px]">Выход</p>
            </NuxtLink> -->
            <a active-class="bg-[#149CFD]" class="flex flex-col items-center gap-1.5 py-1.5 px-3"
                href="whatsapp://send?phone=79522326449&amp;text=Здравствуйте, пишу с сайта ArendaBC.ru, меня интересует вопрос: "
                target="_blank">
                <img src="/img/icons/mobile/whatsapp.svg" alt="whatsapp">
                <p class="font-medium text-white text-[10px]">WhatsApp</p>
            </a>
        </div>
    </div>
</template>