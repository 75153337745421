<template>
    <footer class="bg-brand-dark py-6 pt-8 text-white hidden lg:block">
        <div class="container">
            <div class="nav flex items-start justify-between border-b-gray-500 border-b pb-6">
                <img class="w-[160px] self-center" src="/img/about.png" alt="logo" />
                <div>
                    <h3 class="text-lg pb-3">НАВИГАЦИЯ</h3>
                    <nav>
                        <ul>
                            <li>
                                <NuxtLink href="/" class="hover:text-brand-blue text-base">Главная</NuxtLink>
                            </li>
                            <li>
                                <NuxtLink href="/catalog" class="text-base font-normal hover:text-brand-blue">Каталог</NuxtLink>
                            </li>
                            <li>
                                <NuxtLink href="/news" class="text-base font-normal  hover:text-brand-blue">Новости</NuxtLink>
                            </li>
                            <li>
                                <NuxtLink href="/articles" class="text-base font-normal  hover:text-brand-blue">Статьи</NuxtLink>
                            </li>
                            <li>
                                <NuxtLink href="/about" class="text-base font-normal hover:text-brand-blue">О сервисе</NuxtLink>
                            </li>
                            <li>
                                <NuxtLink href="/contacts" class="text-base font-normal hover:text-brand-blue">Контакты</NuxtLink>
                            </li>
                        </ul>
                    </nav>
                </div>
                <div>
                    <h2 class="text-lg pb-3">ИНФОРМАЦИЯ</h2>
                    <nav>
                        <ul>
                            <li>
                                <nuxt-link to="/docs/51" class="text-base font-normal hover:text-brand-blue" >Политика Cookie-файлов</nuxt-link>
                            </li>
                            <li>
                                <nuxt-link to="/docs/52" class="text-base font-normal hover:text-brand-blue" >Пользовательское соглашение</nuxt-link>
                            </li>
                            <li>
                                <nuxt-link to="/" class="text-base font-normal hover:text-brand-blue" >Политика закрытой базы</nuxt-link>
                            </li>
                            <li>
                                <nuxt-link to="/docs/52" class="text-base font-normal hover:text-brand-blue" >Политика конфиденциальности</nuxt-link>
                            </li>
                        </ul>
                    </nav>
                </div>
                <div>
                    <h2 class="text-lg pb-3">НАШИ КАНАЛЫ</h2>
                    <nav>
                        <ul>
                            <li>
                                <nuxt-link class="text-base font-normal hover:text-brand-blue" to="https://t.me/arendabc_bot" target="_blank" rel="nofollow" >Телеграм-бот</nuxt-link>
                            </li>
                            <li>
                                <nuxt-link class="text-base font-normal hover:text-brand-blue" to="https://t.me/arenda_bc" target="_blank" rel="nofollow">Телеграм-канал</nuxt-link>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
            <div class="flex justify-between pt-6">
                <div>
                    <p class="font-normal text-base">
                        Портал для поиска коммерческой недвижимости
                    </p>
                    <div class="flex items-start gap-1">
                        <h2><span>ARENDA</span> <span class="text-brand-green font-bold">BC</span></h2>
                        <p class="text-sm mt-1">2016-2024</p>
                    </div>
                </div>
                <div class="flex items-center gap-2">
                    <div class="text-right">
                        <nuxt-link to="https://cybermine.studio/" target="_blank" rel="nofollow" >
                            <p class="font-normal text-base">
                                Сайт разработала и обслуживает веб-студия
                            </p>
                            <span class="font-extrabold text-lg">CYBERMINE.STUDIO</span>
                        </nuxt-link>
                    </div>
                    <img src="/img/cybermine.png" alt="cybermine" />
                </div>
            </div>
        </div>
    </footer>
</template>
